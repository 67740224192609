import { useRouter } from 'next/router'
import Image from 'next/image'
import type { CardColorTheme } from './card'
import BlockContent from './blockContent'
import { cx } from '@monorepo/helper/cx'

const ListSection = ({
  contentData,
  colorTheme,
}: {
  contentData?: any
  colorTheme?: CardColorTheme
}) => {
  const { locale = 'de' } = useRouter()

  if (!contentData.isGetToKnowUsBlock) {
    return contentData?.listElementsWithImage?.map((item: any) => {
      return (
        <div
          key={item?.title}
          className="flex-col items-center gap-x-14 gap-y-10 md:flex md:flex-row md:content-start md:justify-between"
        >
          {!!item?.image?.asset?.url && (
            <div className="relative mx-auto my-5 h-[109px] w-[109px] md:my-2 md:mt-0 md:h-[153px] md:w-[167px] md:self-start">
              <Image
                sizes="160px"
                src={item.image.asset.url}
                alt={item.image.asset?.altText || ''}
                fill
                placeholder="blur"
                blurDataURL={item.image.asset.metadata?.lqip!}
                className={cx(
                  item?.round &&
                    'rounded-full border-[5px] border-yellow-500 object-cover md:border-8',
                  'object-contain',
                )}
              />
            </div>
          )}
          {!!item?.content?.[`${locale}Raw`] && (
            <div className="mx-auto mb-14 w-4/5 text-center md:text-left">
              <h2 className="xs:text-2xl mb-4 text-xl leading-tight md:text-3xl">
                {item.title[`${locale}`]}
              </h2>
              <BlockContent
                blocks={item.content[`${locale}Raw`]}
                colorTheme={colorTheme}
              />
            </div>
          )}
        </div>
      )
    })
  }
  return (
    <div className="relative z-0 grid grid-flow-col grid-rows-4 gap-6 md:grid-flow-row md:grid-cols-4 md:grid-rows-none">
      <div className="absolute left-10 top-20 z-0 h-[75%] w-0 border-l-4 border-dotted border-[#86CFC3] md:hidden" />
      <div className="absolute left-[12%] top-10 z-0 hidden h-0 w-[75%] border-t-4 border-dotted border-[#86CFC3] md:block" />
      <div className="z-2 relative mt-4 flex h-20 w-20 items-center justify-center rounded-full bg-[#cfece7] text-[45px] text-white md:my-auto md:justify-self-center">
        <span>1</span>
      </div>
      <div className="z-2 relative mt-4 flex h-20 w-20 items-center justify-center rounded-full bg-[#b6e2db] text-[45px] text-white md:my-auto md:justify-self-center">
        <span>2</span>
      </div>
      <div className="z-2 relative mt-4 flex h-20 w-20 items-center justify-center rounded-full bg-[#9ed9cf] text-[45px] text-white md:my-auto md:justify-self-center">
        <span>3</span>
      </div>
      <div className="z-2 relative mt-4 flex h-20 w-20 items-center justify-center rounded-full bg-[#86CFC3] text-[45px] text-white md:my-auto md:justify-self-center">
        <span>4</span>
      </div>
      {contentData.listElements.map((item: any) => {
        return (
          <div
            key={item?.title}
            className="flex-col content-start text-left text-base leading-6 text-black md:text-center"
          >
            {!!item?.content?.[`${locale}Raw`] && (
              <>
                <h2 className="text-xl/[4] font-medium md:mb-4 md:text-2xl">
                  {item.title[`${locale}`]}
                </h2>
                <BlockContent blocks={item.content[`${locale}Raw`]} />
              </>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default ListSection
