import { useState } from 'react'
import { getTypedLocale } from './typeHelper'
import { useTranslations } from 'next-intl'
import { captureException } from '@sentry/nextjs'
import type {
  SearchError,
  SearchResult,
  SearchResultsCount,
} from '@/app/api/search/route'

type UseSearch = (props: {
  query: string
  filters: string[]
  locale: string
}) => [
  SearchResult & {
    isLoading: boolean
    error: any
  },
  (abortController: AbortController) => void,
]

export const useSearch: UseSearch = ({
  query,
  filters,
  locale: untypedLocale,
}) => {
  const locale = getTypedLocale(untypedLocale)
  const t = useTranslations('search')
  const [results, setResults] = useState<any>([])
  const [resultsCount, setResultsCount] = useState<SearchResultsCount>()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<any>()

  const url = `/api/search`

  const search = (abortController: AbortController) => {
    setIsLoading(true)

    if (query !== '') {
      const searchParams = new URLSearchParams()
      searchParams.set('term', query)
      searchParams.set('locale', locale)
      searchParams.set('types', filters.join(','))

      fetch(`${url}?${searchParams.toString()}`, {
        signal: abortController.signal,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data: SearchResult | SearchError) => {
          if (data) {
            setIsLoading(false)
            if ('results' in data) {
              setError(null)
              setResultsCount(data.resultsCount)
              setResults(data.results)
            } else if (data.error) {
              setError(data.error)
            }
          }
        })
        .catch((err: any) => {
          if (!err?.message?.startsWith('AbortError')) {
            captureException(err, {
              extra: {
                url,
                query,
                filters,
                locale,
              },
            })
            console.error(err)
            setIsLoading(false)
            setError(t('error'))
          }
        })
    }
  }

  return [{ results, resultsCount, isLoading, error }, search]
}
