'use client'

import type { ReactNode } from 'react'
import { useState } from 'react'
import Link from 'next/link'
import { PreviewSuspense } from 'next-sanity/preview'
import { BsLink45Deg as LinkIcon, BsPower as PowerIcon } from 'react-icons/bs'
import { useRouter } from 'next/dist/client/router'
import { usePathname } from 'next/navigation'
import styles from './SanityClientPreviewWrapper.module.css'

type ClientPreviewWrapperProps = {
  children: ReactNode
  urlOrigin: string
  isPreview?: boolean
}

export default function ClientPreviewWrapper({
  children,
  urlOrigin,
  isPreview,
}: ClientPreviewWrapperProps) {
  const [copyText, setCopyText] = useState('Copy preview link')
  let pathname = ''

  if (!isPreview) return <>{children}</>

  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    pathname = usePathname() || ''
  } catch (error) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    pathname = useRouter().pathname || ''
  }

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(
      `${urlOrigin}/api/public-preview?slug=${pathname}`,
    )
    setCopyText('Copied!')
  }
  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.innerWrapper}>
          <div className={styles.flexBox}>
            <div>
              <p className={styles.headline}>Preview!</p>
              <p className={styles.subline}>
                You might see unpublished content
              </p>
            </div>

            <button className={styles.button} onClick={copyToClipboard}>
              <LinkIcon className={styles.icon} />
              <span>{copyText}</span>
            </button>
            <Link href="/api/exit-preview" className={styles.button}>
              <PowerIcon className={styles.icon} />
              <span>Exit preview</span>
            </Link>
          </div>
        </div>
      </div>
      <PreviewSuspense fallback={'Loading...'}>{children}</PreviewSuspense>
    </>
  )
}
