import React from 'react'
import { cx } from '@monorepo/helper/cx'
import type { LinkProps } from 'next/link'
import Link from 'next/link'

type FilterButtonProps = {
  name: string
  value: string
  active: boolean
  onClick: (value: string) => void
}

export const FilterButton = (props: FilterButtonProps) => {
  const toggleClass = () => {
    props.onClick(props.value)
  }

  return (
    <button
      key={props.value}
      value={props.value}
      className={`h-12 w-full cursor-pointer rounded-none border border-red-500 px-6 text-sm text-red-500 transition-colors ${
        props.active ? 'bg-yellow-500' : 'bg-white hover:bg-yellow-500'
      }`}
      onClick={toggleClass}
    >
      {props.name}
    </button>
  )
}

export const BlackButton = ({ className, children, ...rest }: any) => {
  return (
    <a
      className={cx(
        'inline-block cursor-pointer border border-gray-700 bg-white p-3 px-8 leading-none hover:bg-gray-700 hover:text-white focus:bg-gray-700 focus:text-white',
        className,
      )}
      {...rest}
    >
      {children}
    </a>
  )
}

export const BlackButtonLink = ({
  className,
  children,
  ...rest
}: LinkProps & { children: React.ReactNode; className?: string }) => {
  return (
    <Link
      className={cx(
        'inline-block border border-black bg-white p-2 px-4 leading-none hover:bg-black hover:text-white focus:bg-black focus:text-white sm:p-3 sm:px-8',
        className,
      )}
      {...rest}
    >
      {children}
    </Link>
  )
}
