import React, { useState, useEffect } from 'react'
import { cx } from '@monorepo/helper/cx'
import Img from '@/components/Img'

const LOOP_INTERVAL = 6000

const HeaderCarousel = ({ images }: any) => {
  const [fadingIndex, setFadingIndex] = useState(-1)
  const [nextIndex, setNextIndex] = useState(1)
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    let current = 0
    let fading = -1
    let next = 1

    const rotateImage = () => {
      fading = current
      current = (current + 1) % images.length
      next = (current + 1) % images.length

      setFadingIndex(fading)
      setTimeout(() => {
        setCurrentIndex(current)
        setNextIndex(next)
        setFadingIndex(-1)
      }, 1000)
    }

    const interval = setInterval(rotateImage, LOOP_INTERVAL)
    return () => {
      window.clearInterval(interval)
    }
  }, [images])

  return (
    <div className="relative h-full">
      {images.map((image: any, i: number) => (
        <div
          key={image.asset._id}
          className={cx(
            'absolute inset-0 h-full transition-opacity duration-1000',
            currentIndex !== i && nextIndex !== i && '-z-1',
            nextIndex === i && 'z-0',
            currentIndex === i && 'z-1',
            fadingIndex === i && 'opacity-0',
          )}
        >
          <Img priority={i === 0} image={image} className="h-full" />
        </div>
      ))}
    </div>
  )
}

export default HeaderCarousel
