import type { LocaleString } from '../.generated/sanityTypes/graphql'

export const isLocalString = (obj: any): obj is LocaleString => {
  return obj && typeof obj === 'object' && 'en' in obj && 'de' in obj
}

export type Locale = keyof Omit<LocaleString, '_key' | '__typename' | '_type'>

const supportedLocales = ['en', 'de', 'fr', 'it'] as const
const fallbackLocale = 'de'

export const getTypedLocale = (locale?: string): Locale => {
  if (locale && supportedLocales.includes(locale as Locale))
    return locale as Locale
  return fallbackLocale
}
