import type { Image as SanityImage } from '@/.generated/sanityTypes/graphql'
import { Vimeo, type VimeoOptions } from '@monorepo/helper/Vimeo'
import { cx } from '@monorepo/helper/cx'

const defautOptions: VimeoOptions = {
  background: false,
  portrait: false,
  responsive: false,
  quality: 'auto',
}

type VimeoPlayerProps = {
  className?: string
  videoId?: number
  videoUrl?: string
  options?: VimeoOptions
  customPoster?: SanityImage
}

const VimeoPlayer = (props: VimeoPlayerProps) => {
  const { className, videoId, videoUrl, options: inputOptions } = props
  const options = { ...defautOptions, ...inputOptions }
  const urlOrId = videoUrl || videoId

  if (!urlOrId) {
    return null
  }

  return (
    <div className={cx(className, 'relative h-full w-full bg-yellow-500')}>
      <Vimeo
        autoplay={options.background}
        options={{ ...options }}
        urlOrId={urlOrId}
        className={
          options.background
            ? 'vimeo-background-container absolute inset-0'
            : 'vimeo-container'
        }
        iframeStyle={options.background ? {} : undefined}
        customPoster={props.customPoster || undefined}
      />
    </div>
  )
}

export default VimeoPlayer
