import type { LocaleString } from '@/.generated/sanityTypes/graphql'
import { getTypedLocale } from '../lib/typeHelper'
import { cx } from '@monorepo/helper/cx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'
import { Carousel as ReactCarousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'

type ImageLike = {
  _key: string
  altText?: string
  asset: {
    url: string
    metadata: {
      dimensions: {
        width: number
        height: number
      }
      lqip: string
    }
    title?: string
    altText?: string
  }
  title?: LocaleString
}

type CarouselProps = {
  images: ImageLike[]
  className?: string
  slideContainerClassName?: string
  showArrows?: boolean
  showIndicators?: boolean
  onChange?: (index: number, item: React.ReactNode) => void
  selectedItem?: any
  dynamicHeight?: boolean
  objectFit?: string
  autoPlay?: boolean
  imageStyle?: any
  containerStyle?: any
  fullscreen?: boolean
}

const Carousel = ({
  images,
  className = '',
  slideContainerClassName = '',
  showArrows = true,
  showIndicators = true,
  dynamicHeight = false,
  objectFit,
  onChange,
  selectedItem = 0,
  autoPlay = false,
  imageStyle = {},
  containerStyle = {},
  fullscreen = false,
}: CarouselProps) => {
  const locale = getTypedLocale(useRouter().locale)
  const filteredImages = images.filter((image) => Boolean(image?.asset?.url))
  if (filteredImages.length === 0) return null

  return (
    <ReactCarousel
      className={className}
      showThumbs={false}
      showStatus={false}
      showIndicators={showIndicators}
      infiniteLoop={true}
      emulateTouch={true}
      dynamicHeight={dynamicHeight}
      showArrows={showArrows}
      autoPlay={autoPlay}
      interval={3000}
      onChange={onChange}
      selectedItem={selectedItem}
    >
      {filteredImages.map((image) => {
        return (
          <div
            key={image._key}
            className={slideContainerClassName}
            style={containerStyle}
          >
            <Image
              sizes={
                fullscreen
                  ? '100vw'
                  : '(min-width: 900px) 768px, (min-width: 640px) 83.33vw, 100vw'
              }
              className={cx(fullscreen && 'h-full')}
              src={image.asset.url}
              width={image.asset.metadata?.dimensions?.width!}
              height={image.asset.metadata?.dimensions?.height!}
              alt={
                image.altText || image.asset.title || image.asset.altText || ''
              }
              style={{ ...imageStyle, objectFit }}
            />
            {image.title && image.title[locale] && (
              <div className="absolute bottom-0 w-full pb-10 pt-4 text-center text-white">
                <div className="absolute bottom-0 top-0 w-full bg-black opacity-25"></div>
                <div className="text-md relative px-3 text-white">
                  {image.title[locale]}
                </div>
              </div>
            )}
          </div>
        )
      })}
    </ReactCarousel>
  )
}

export default Carousel
