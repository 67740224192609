import React from 'react'

type IconProps = {
  className?: string
}

export const ChevronDown = ({ className }: IconProps) => {
  return (
    <svg className={className} viewBox="0 0 26 26">
      <path d="M6.414 7.586l-2.828 2.828L13 19.828l9.414-9.414-2.828-2.828L13 14.172 6.414 7.586z" />
    </svg>
  )
}

export const ChevronCircleDown = ({ className }: IconProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="chevron-circle-down"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={className}
    >
      <path
        fill="currentColor"
        d="M504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zM273 369.9l135.5-135.5c9.4-9.4 9.4-24.6 0-33.9l-17-17c-9.4-9.4-24.6-9.4-33.9 0L256 285.1 154.4 183.5c-9.4-9.4-24.6-9.4-33.9 0l-17 17c-9.4 9.4-9.4 24.6 0 33.9L239 369.9c9.4 9.4 24.6 9.4 34 0z"
        className=""
      ></path>
    </svg>
  )
}

export const ChevronUp = ({ className }: IconProps) => {
  return (
    <svg className={className} viewBox="0 0 26 26">
      <path d="M13 6.172l-9.414 9.414 2.828 2.828L13 11.828l6.586 6.586 2.828-2.828L13 6.172z" />
    </svg>
  )
}

export const SearchIcon = ({ className }: IconProps) => {
  return (
    <svg className={className} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M15.5 14h-.79l-.28-.27a6.51 6.51 0 10-.7.7l.27.28v.79l5 4.99L20.49 19zm-6 0A4.5 4.5 0 1114 9.5 4.494 4.494 0 019.5 14z" />
    </svg>
  )
}

export const PinIcon = ({ className }: IconProps) => {
  return (
    <svg className={className} viewBox="0 0 13.151 13.151">
      <path d="M5.576 1.096A3.833 3.833 0 001.74 4.932c0 2.877 3.836 7.124 3.836 7.124s3.836-4.247 3.836-7.124a3.833 3.833 0 00-3.836-3.836zm0 5.206a1.37 1.37 0 111.37-1.37 1.37 1.37 0 01-1.37 1.37z" />
      <path d="M0 0h13.151v13.151H0z" fill="none" />
    </svg>
  )
}

export const PhoneIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      viewBox="0 0 12.062 12.062"
      width={20}
      height={20}
    >
      <path d="M0 0h12.062v12.061H0z" fill="none" />
      <path
        fill="currentColor"
        d="M10.554 7.77l-2.647-.307L6.639 8.73a7.561 7.561 0 01-3.312-3.312l1.272-1.272-.307-2.638H1.523a8.544 8.544 0 009.031 9.031V7.77z"
      />
    </svg>
  )
}

export const MailIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      viewBox="1 1 10.062 10.062"
      width={20}
      height={20}
    >
      <path d="M0 0h12.062v12.061H0z" fill="none" />
      <path
        fill="currentColor"
        d="M11.056 2.01H1.01l-.005 8.041h10.051zm-1.005 7.036H2.01V4.02l4.021 2.513 4.02-2.513zm-4.02-3.518L2.01 3.015h8.041z"
      />
    </svg>
  )
}

export const AddressCardIcon = ({ className }: IconProps) => {
  return (
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="far"
      data-icon="address-card"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 576 512"
      width={20}
      height={20}
      className={className}
    >
      <path
        fill="currentColor"
        d="M528 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h480c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm0 400H48V80h480v352zM208 256c35.3 0 64-28.7 64-64s-28.7-64-64-64-64 28.7-64 64 28.7 64 64 64zm-89.6 128h179.2c12.4 0 22.4-8.6 22.4-19.2v-19.2c0-31.8-30.1-57.6-67.2-57.6-10.8 0-18.7 8-44.8 8-26.9 0-33.4-8-44.8-8-37.1 0-67.2 25.8-67.2 57.6v19.2c0 10.6 10 19.2 22.4 19.2zM360 320h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8zm0-64h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8H360c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8z"
      ></path>
    </svg>
  )
}

export const LinkedinIcon = ({ className }: IconProps) => {
  return (
    <svg className={className} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M0 0v24h24v-24h-24zm8 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.397-2.586 7-2.777 7 2.476v6.759z"
      />
    </svg>
  )
}

export const PortraitIcon = ({ className }: IconProps) => {
  return (
    <svg className={className} viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none" />
      <path
        fill="currentColor"
        d="M12 12.25A2.25 2.25 0 109.75 10 2.253 2.253 0 0012 12.25zm4.5 4c0-1.5-3-2.25-4.5-2.25s-4.5.75-4.5 2.25V17h9zM21 3H3v18h18zm-2 16H5V5h14z"
      />
    </svg>
  )
}

export const CloseIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M11.854 4.146a.5.5 0 010 .708l-7 7a.5.5 0 01-.708-.708l7-7a.5.5 0 01.708 0z"
        clipRule="evenodd"
      ></path>
      <path
        fillRule="evenodd"
        d="M4.146 4.146a.5.5 0 000 .708l7 7a.5.5 0 00.708-.708l-7-7a.5.5 0 00-.708 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}

type MenuIconProps = {
  color: string
}

export const MenuIcon = ({ color }: MenuIconProps) => {
  return (
    <svg
      id="menu"
      xmlns="http://www.w3.org/2000/svg"
      style={{ stroke: color }}
      viewBox="0 0 34 34"
    >
      <line
        x1="9.42"
        y1="10.5"
        x2="25.3"
        y2="10.5"
        fill="none"
        strokeWidth="2"
        style={{ isolation: 'isolate' }}
      ></line>
      <line
        x1="9.42"
        y1="16.57"
        x2="25.3"
        y2="16.57"
        fill="none"
        strokeWidth="2.12"
        style={{ isolation: 'isolate' }}
      ></line>
      <line
        x1="9.42"
        y1="22.46"
        x2="19.36"
        y2="22.46"
        fill="none"
        strokeWidth="2.12"
        style={{ isolation: 'isolate' }}
      ></line>
    </svg>
  )
}
