import React from 'react'
import { cx } from '@monorepo/helper/cx'
import withNewLines from '@/lib/withNewLines'
import VimeoPlayer from '@/components/VimeoPlayer'
import Link from 'next/link'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import type { Image as SanityImage } from '@/.generated/sanityTypes/graphql'
import Img from '@/components/Img'

type TileProps = {
  label: any
  title: any
  textSize: string
  color: any
  videos?: any[]
  videoId?: number
  teaser: any
  link: any
  image?: SanityImage
  children?: React.ReactNode
}

const defaultStyles = {
  label: 'transform scale-85',
  title: 'text-3xl leading-none mt-4',
  lead: 'text-xl leading-1_1 m-2',
}

// The keys of this object correspond to the tile's size on the various
// breakpoints, so e.g. 1/3-1/2-1 means the tile has size 1/3 on lg,
// 1/2 on md and 1 below.
const tileStyles = {
  '2/3-1-1': {
    label: 'sm:scale-100',
    title: 'sm:text-4xl md:text-5xl xl:text-6xl lg:mt-6',
    lead: 'sm:text-2xl md:text-3xl xl:text-4xl md:leading-1_1 lg:mt-3',
  },
  '2/3-1/2-1': {
    label: 'sm:scale-100',
    title: 'sm:text-4xl md:text-5xl xl:text-6xl lg:mt-6',
    lead: 'sm:text-2xl md:text-3xl xl:text-4xl lg:mt-3',
  },
  '1-1-1': {
    label: 'sm:scale-100 lg:scale-125',
    title: 'sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl lg:mt-8',
    lead: 'sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl lg:mt-5',
  },
  '1/2-1/2-1': {
    label: 'sm:scale-100',
    title: 'sm:text-3xl md:text-4xl lg:text-5xl lg:mt-6',
    lead: 'sm:text-xl md:text-2xl lg:text-3xl lg:mt-3',
  },
  '1/3-1/2-1': {
    label: 'sm:scale-100',
    title: 'sm:text-3xl lg:text-3xl xl:text-4xl lg:mt-3',
    lead: 'sm:text-2xl lg:text-2xl xl:text-3xl lg:mt-1',
  },
}

const Tile = ({
  label,
  title,
  textSize,
  color,
  teaser,
  videoId,
  link,
  image,
  children,
}: TileProps) => {
  const t = useTranslations('tiles')
  const tileStyle = tileStyles[textSize as keyof typeof tileStyles]
  if (!tileStyle) throw new Error(`Tile style ${textSize} does not exist`)

  return (
    <div className="group relative h-full">
      <Link href={link ? link : '/'}>
        <div className="absolute inset-0 overflow-hidden">
          {videoId ? (
            <div className="absolute inset-0 min-h-px overflow-hidden transition-transform duration-300 ease-in-out group-hover:scale-105">
              <VimeoPlayer
                options={{ quality: '360p', background: true }}
                videoId={videoId}
                customPoster={image || undefined}
              />
            </div>
          ) : (
            image?.asset?.url && (
              <Img
                image={image}
                fill
                objectFit="cover"
                className="h-full min-h-px w-full transition-transform duration-300 ease-in-out group-hover:scale-105"
              />
            )
          )}
        </div>

        <div className="overlay absolute inset-0 flex flex-col justify-end">
          <div className="min-h-2/3 z-10 flex items-center justify-center overflow-hidden p-4 text-center">
            <div className="w-4/5">
              {label && (
                <div
                  className={cx(
                    'inline-block bg-teal-400 px-3 py-1 text-xs uppercase leading-none',
                    defaultStyles.label,
                    tileStyle.label,
                  )}
                  style={color ? { backgroundColor: color } : {}}
                >
                  {label}
                </div>
              )}
              <div
                className={cx(
                  'text-white',
                  defaultStyles.title,
                  tileStyle.title,
                )}
              >
                {withNewLines(title)}
              </div>
              <div
                className={cx(
                  'mx-auto font-light text-white',
                  defaultStyles.lead,
                  tileStyle.lead,
                )}
              >
                {teaser}
              </div>
              {children}
              <div className="xs:mt-3 scale-85 xs:scale-90 mx-auto mt-2 inline-block border border-white px-4 py-1 text-white outline-none transition-colors duration-150 focus:bg-white focus:text-black group-hover:bg-white group-hover:text-black sm:px-8 sm:py-2 md:mt-6 md:scale-100">
                {t('more')}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

type GenericTileProps = {
  tile: {
    title?: any
    sectionTitle?: any
    colorlist?: any
    link?: any
    teaser?: any
    image?: any
  }
  textSize: string
}

export const StoryTile = ({ tile, textSize }: GenericTileProps) => {
  const { locale = 'de' } = useRouter()
  const t = useTranslations('advisors')
  const title =
    tile.title?.[locale] ||
    `${tile.link.title?.[locale]}\\n${tile.link?.subtitle?.[locale] || ''}`
  const teaser = tile.teaser?.[locale] || tile.link?._rawExcerpt?.[locale]
  const image = tile.image || tile.link?.image

  return (
    <Tile
      label={tile.sectionTitle?.[locale] ?? t('stories')}
      title={title}
      textSize={textSize}
      color={tile.colorlist}
      teaser={teaser}
      link={`/${locale}/stories/${tile.link.slug.current}`}
      image={image}
    />
  )
}

export const ProjectTile = ({ tile, textSize }: GenericTileProps) => {
  const { locale = 'de' } = useRouter()
  const t = useTranslations('menu')
  const title = tile.title?.[locale] || tile.link?.title?.[locale]
  const image = tile.image || tile.link.projectImages[0]

  return (
    <Tile
      label={tile.sectionTitle?.[locale] ?? t('projects')}
      title={title}
      textSize={textSize}
      color={tile.colorlist}
      teaser={tile.teaser?.[locale]}
      link={`/${locale}/projects/${tile.link.slug.current}`}
      image={image}
    />
  )
}

export const PageTile = ({ tile, textSize }: GenericTileProps) => {
  const { locale = 'de' } = useRouter()
  const title = tile.title?.[locale] || tile.link?.pageHeader?.title?.[locale]
  const label =
    tile.sectionTitle?.[locale] ?? tile.link.pageHeader.sectionTitle[locale]
  const image = tile.image || tile.link.image

  return (
    <Tile
      label={label}
      textSize={textSize}
      title={title}
      color={tile.colorlist}
      teaser={tile.teaser?.[locale]}
      link={`/${locale}/${tile.link.pageSlug.current}`}
      image={image}
    />
  )
}

export const AboutUsVideoTile = ({ tile, textSize }: GenericTileProps) => {
  const { locale = 'de' } = useRouter()
  const title = tile.title?.[locale] || tile.link?.title?.[locale]
  const label = tile.sectionTitle?.[locale] ?? tile.link.sectionTitle[locale]
  const teaser = (tile.teaser || tile.link.subtitle)[locale]

  // get random video
  const videosCount = tile?.link?.videos?.length || 0
  const randomId = Math.floor(Math.random() * videosCount)
  const video = tile?.link?.videos?.[randomId]
  const videoId = video?.videoShort?.[locale]
  const longVideoId = video?.videoLong?.[locale]

  return (
    <Tile
      label={label}
      textSize={textSize}
      title={title}
      color={tile.colorlist}
      teaser={teaser}
      link={`/${locale}/about?v=${longVideoId}`}
      videoId={videoId}
      image={tile.image}
    />
  )
}
