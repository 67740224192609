import type {
  LocaleString,
  SanityImageAsset,
} from '@/.generated/sanityTypes/graphql'
import { getTypedLocale } from '../lib/typeHelper'

import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'

export const SIZE_DEFINITIONS = {
  small: { style: { maxWidth: '180px' } },
  medium: { style: { maxWidth: '367px' } },
  large: { style: { maxWidth: '572px' } },
  full: { style: {} },
} as const

type RichTextImageProps = {
  value: {
    asset: SanityImageAsset
    title?: LocaleString
    surtitle?: LocaleString
    size?: keyof typeof SIZE_DEFINITIONS
  }
  className?: string
}

const RichTextImage = ({ value, className }: RichTextImageProps) => {
  const locale = getTypedLocale(useRouter().locale)
  const image = value.asset
  const title = value.title?.[locale] || image.title
  const description = value.surtitle?.[locale] || image.description

  if (!image.url) return null

  const sizeDefinition = SIZE_DEFINITIONS[value.size || 'full']

  return (
    <div
      className={className}
      style={sizeDefinition ? sizeDefinition.style : {}}
    >
      <Image
        src={image.url}
        alt={image.altText || ''}
        width={image.metadata?.dimensions?.width!}
        height={image.metadata?.dimensions?.height!}
        placeholder="blur"
        blurDataURL={image.metadata?.lqip!}
      />
      {(title || description) && (
        <div className="pt-4">
          {description && (
            <span className="block text-center text-sm text-red-500">
              {description}
            </span>
          )}
          {title && <span className="text-md block text-center">{title}</span>}
        </div>
      )}
    </div>
  )
}

export default RichTextImage
