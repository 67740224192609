import React from 'react'
import { cx } from '@monorepo/helper/cx'

import { YellowTag } from '@/components/tags'
import BlockContent from '@/components/blockContent'
import SectionItemsLayout from '@/components/sectionItemsLayout'
import withNewLines from '@/lib/withNewLines'
import { useRouter } from 'next/router'
import ListSection from './ListSection'
import { cva } from 'class-variance-authority'

// HELPER COMPONENTS

export type CardColorTheme = 'red' | 'blue' | 'blue_white'

export const colorThemeVariants = cva('...', {
  variants: {
    textColorTheme: {
      default: 'text-[#3f3837]',
      red: 'text-red-500',
      blue: 'text-yellow-500',
      blue_white: 'text-white',
    },
    bgColorTheme: {
      default: 'white',
      red: 'bg-white',
      blue: 'bg-dark-blue',
      blue_white: 'bg-dark-blue',
    },
  },
  defaultVariants: {
    textColorTheme: 'default',
  },
})

type CardHeaderProps = {
  sectionTitle?: any | null
  title?: any | null
  subtitle?: any | null
  colorTheme?: CardColorTheme
}

const CardHeader = ({
  sectionTitle,
  title,
  subtitle,
  colorTheme,
}: CardHeaderProps) => {
  const { locale = 'de' } = useRouter()
  const nonNullableColorTheme = colorTheme || undefined
  return (
    <div className="mx-auto max-w-3xl pb-4 text-center lg:pb-8">
      {sectionTitle?.[locale] && <YellowTag>{sectionTitle[locale]}</YellowTag>}
      {title?.[locale] && (
        <h2
          className={cx(
            'xs:text-4xl m-9 text-3xl leading-none md:mb-0 md:mt-6 md:text-5xl lg:text-6xl',
            colorThemeVariants({
              textColorTheme: nonNullableColorTheme || 'red',
            }),
          )}
        >
          {withNewLines(title[locale])}
        </h2>
      )}
      {subtitle?.[locale] && (
        <p
          className={cx(
            'mx-auto mt-3 text-xl leading-tight md:mt-6 md:px-4 md:text-2xl',
            colorThemeVariants({
              textColorTheme: nonNullableColorTheme || 'red',
            }),
          )}
        >
          {withNewLines(subtitle[locale])}
        </p>
      )}
    </div>
  )
}

// MAIN COMPONENT

type CardProps = {
  headerData?: CardHeaderProps | null
  contentData?: any
  className?: string
  style?: React.CSSProperties
  noStyles?: boolean
  noPadding?: boolean
  fullWidth?: boolean
  colorTheme?: CardColorTheme
  children?: React.ReactNode
}

const Card = ({
  headerData,
  contentData,
  className,
  style,
  noStyles,
  noPadding,
  fullWidth,
  colorTheme,
  children,
}: CardProps) => {
  const { locale = 'de' } = useRouter()
  const hasContent = Boolean(contentData?.content?.[`${locale}Raw`] || children)
  const hasHeader =
    Boolean(headerData?.sectionTitle?.[locale]) ||
    Boolean(headerData?.title?.[locale])
  const hasList = contentData?.hasOwnProperty('isGetToKnowUsBlock')
  const hasBlueBackgroud = colorTheme === 'blue' || colorTheme === 'blue_white'
  return (
    <div className={cx(!fullWidth && 'mx-auto w-full max-w-screen-xl')}>
      <div className={cx(!(fullWidth || noPadding) && 'px-0 sm:px-5')}>
        <div
          className={cx(
            'mb-8',
            colorThemeVariants({
              textColorTheme: colorTheme,
              bgColorTheme: colorTheme,
            }),
            !(noStyles || fullWidth) && 'px-4 pb-6 pt-6 md:py-8 md:text-lg',
            !hasBlueBackgroud && !(noStyles || fullWidth) && 'bg-white',
            hasList && contentData?.isGetToKnowUsBlock && 'p-8 pt-6',
          )}
        >
          {hasHeader && <CardHeader {...headerData} colorTheme={colorTheme} />}
          {hasContent && (
            <div
              className={cx(
                'mb-4',
                !(noStyles || fullWidth) && 'mx-auto max-w-3xl',
                colorThemeVariants({
                  textColorTheme: hasBlueBackgroud ? colorTheme : 'default',
                  bgColorTheme: hasBlueBackgroud ? colorTheme : 'default',
                }),
                className,
              )}
              style={style}
            >
              {contentData?.content?.[`${locale}Raw`] && (
                <BlockContent
                  blocks={contentData.content[`${locale}Raw`]}
                  colorTheme={colorTheme}
                />
              )}
              {children}
            </div>
          )}
          {hasList && (
            <div
              className={cx(
                'mb-4 md:mt-8',
                !(noStyles || fullWidth) && 'mx-auto max-w-3xl',
                colorThemeVariants({
                  textColorTheme: colorTheme,
                  bgColorTheme: colorTheme,
                }),
              )}
              style={style}
            >
              <ListSection contentData={contentData} colorTheme={colorTheme} />
              {children}
            </div>
          )}
          <SectionItemsLayout
            layoutName={contentData?.layout}
            items={contentData?.sectionItems}
          />
        </div>
      </div>
    </div>
  )
}

export default Card
