import React, { useState, useEffect, useRef } from 'react'
import { useTranslations } from 'next-intl'

import SearchResults from '@/components/searchResults'
import { useSearch } from '@/lib//useSearch'
import { CloseIcon } from '@/components/icons'
import { useRouter } from 'next/router'
import { useDebounce } from 'use-debounce'
import { trackGoal } from '@/lib/analytics'

export const SearchQueryContext = React.createContext('')

// HELPERS

type SearchBarProps = {
  query: string
  setQuery: (newQuery: string) => void
  searchOpen: boolean
  setSearchOpen: (newIsOpen: boolean) => void
}

const ClearButton = ({
  onClick,
  fade,
  className,
}: {
  onClick: () => void
  fade: boolean
  className?: string
}) => {
  const t = useTranslations('search')
  return (
    <button
      className={`appearance-none text-black outline-none transition-opacity duration-300 focus:outline-none ${
        fade ? 'opacity-25' : 'opacity-100'
      } ${className || ''}`}
      style={{ lineHeight: 0 }}
      onClick={onClick}
      aria-label={t('clear')}
    >
      <CloseIcon className="inline-flex text-2xl" />
    </button>
  )
}

const SearchBar = ({
  query,
  setQuery,
  searchOpen,
  setSearchOpen,
}: SearchBarProps) => {
  const t = useTranslations('search')
  const inputEl = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (searchOpen) {
      inputEl.current?.focus()
    }
  }, [searchOpen])

  return (
    <div className="w-full">
      <div className="text-red-highlight mx-auto flex w-4/5 max-w-screen-lg cursor-pointer py-2 sm:w-2/3">
        <input
          ref={inputEl}
          type="text"
          id="global-search-query"
          autoComplete="off"
          className="w-full py-1 text-red-500 placeholder-red-500 outline-none"
          placeholder={t('search_placeholder')}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        {/* This clear button is visible on larger screens and just clears the search.
        but does not close the search bar. There is another button to close it. */}
        <ClearButton
          onClick={() => {
            setQuery('')
          }}
          fade={query ? false : true}
          className="hidden sm:block"
        />
        {/* This clear button is visible on smaller / mobile screens and directly
        closes the search bar. */}
        <ClearButton
          onClick={() => {
            setQuery('')
            setSearchOpen(false)
          }}
          fade={false}
          className="sm:hidden"
        />
      </div>
      <button
        className="absolute right-0 top-0 hidden h-full appearance-none px-4 text-gray-500 outline-none focus:outline-none sm:block"
        onClick={() => setSearchOpen(false)}
      >
        {t('close')}
      </button>
    </div>
  )
}

// MAIN COMPONENT

type SearchProps = {
  searchOpen: boolean
  setSearchOpen: (newSearchOpen: boolean) => void
}

const Search = ({ searchOpen, setSearchOpen }: SearchProps) => {
  const { locale = 'de' } = useRouter()
  const [query, setQuery] = useState('')
  const [debouncedQuery] = useDebounce(query, 300)
  const [filters, setFilters] = useState<any[]>([])

  const [{ results, resultsCount, isLoading, error }, search] = useSearch({
    query: debouncedQuery,
    filters: filters,
    locale,
  })

  useEffect(() => {
    const abortController = new AbortController()
    if (search) {
      debouncedQuery && trackGoal('use:search', { searchQuery: debouncedQuery })
      search(abortController)
    }
    return () => abortController.abort('Search component unmounted')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedQuery, filters])

  useEffect(() => {
    if (!searchOpen) {
      setQuery('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchOpen])

  return (
    <>
      <div
        className="absolute top-0 mt-2 w-full transition duration-500 ease-in-out"
        style={{ transform: searchOpen ? '' : 'translateY(-4rem)' }}
      >
        <SearchBar
          query={query}
          setQuery={setQuery}
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
        />
      </div>
      <div
        className="mt-12"
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <SearchQueryContext.Provider value={query}>
          <SearchResults
            searchOpen={searchOpen}
            query={query}
            results={results}
            error={error}
            resultsCount={resultsCount}
            filters={filters}
            setFilters={setFilters}
            isLoading={isLoading}
            closeSearch={() => {
              setSearchOpen(false)
            }}
          />
        </SearchQueryContext.Provider>
      </div>
    </>
  )
}

export default Search
