import React from 'react'
import {
  PortableText,
  type PortableTextBlockComponent,
  type PortableTextListComponent,
  type PortableTextTypeComponent,
} from '@portabletext/react'

import RichTextVideo from './richTextVideo'
import RichTextImage from './richTextImage'
import RichTextCarousel from './richTextCarousel'
import RichTextPDF from './richTextPDF'
import { useRouter } from 'next/router'
import type { SanityImageAsset } from '@/.generated/sanityTypes/graphql'

const StringOrRichText = (props: { value: any }) => {
  if (typeof props.value === 'string') {
    return <>{props.value}</>
  } else {
    return <PortableText value={props.value} onMissingComponent={false} />
  }
}

const serializers = (colorTheme: string, locale: string) => {
  const vimeo: PortableTextTypeComponent = (props: any) => (
    <RichTextVideo
      className="absolute left-0 top-0 h-full w-full"
      srcURL={props.value.url && props.value.url[locale]}
      title={props.value.title && props.value.title[locale]}
      size={props.value.size}
      colorTheme={colorTheme}
    />
  )

  const richTextImage: PortableTextTypeComponent = (props: {
    value: { asset: SanityImageAsset }
  }) => {
    if (!props.value.asset) return null
    return (
      <RichTextImage
        className="mx-auto my-5 first:mt-0 sm:my-10"
        value={props.value}
      />
    )
  }

  const richTextCarousel: PortableTextTypeComponent = (props: any) => {
    return (
      <RichTextCarousel
        className="my-5 first:mt-0 sm:my-10"
        imageOptions={props?.options?.imageOptions ?? {}}
        images={props?.value?.images ?? []}
        caption={props?.value?.caption?.[locale]}
        size={props?.value?.size}
      />
    )
  }

  const richTextPDF: PortableTextTypeComponent = (props) => {
    return <RichTextPDF title={props?.value?.title} file={props?.value?.file} />
  }

  // This block content component is used only in the annual report -
  // the website ignores it
  const richTextStoryAnnualReportFold: PortableTextTypeComponent = () => {
    return null
  }

  const block: PortableTextBlockComponent = ({ value }) => {
    switch (value.style) {
      case 'sectionHeading':
        return (
          <h2 className="xs:text-3xl text-center text-2xl leading-tight text-red-500 md:text-4xl">
            <StringOrRichText value={value} />
          </h2>
        )

      case 'sectionSubHeading':
        return (
          <h2 className="xs:text-2xl text-center text-xl leading-tight md:text-3xl">
            <StringOrRichText value={value} />
          </h2>
        )

      case 'smallHeading':
        return (
          <h2 className="xs:mt-8 mb-2 mt-4 text-red-500">
            {' '}
            <StringOrRichText value={value} />
          </h2>
        )

      case 'factHeading':
        return (
          <h2 className="text-center text-red-500">
            {' '}
            <StringOrRichText value={value} />
          </h2>
        )

      case 'factSubHeading':
        return (
          <h2 className="mb-5 text-center">
            {' '}
            <StringOrRichText value={value} />
          </h2>
        )

      default:
        return (
          <div className="mb-2">
            <StringOrRichText value={value} />
          </div>
        )
    }
  }

  const list: PortableTextListComponent = (props) => (
    <ul className="ml-5 list-disc">{props.children}</ul>
  )

  return {
    types: {
      vimeo,
      richTextImage,
      richTextCarousel,
      richTextStoryAnnualReportFold,
      block,
      richTextPDF,
    },
    list,
    block,
  }
}

// MAIN COMPONENT

const BlockContent = ({
  blocks,
  colorTheme,
}: {
  blocks: any
  colorTheme?: string
}) => {
  const { locale = 'de' } = useRouter()
  return blocks ? (
    <PortableText
      value={blocks}
      components={serializers(colorTheme ?? '', locale)}
    />
  ) : null
}

export default BlockContent
