import type { AllPagesQuery, Image } from '@/.generated/sanityTypes/graphql'

import React from 'react'
import Head from 'next/head'
import { cx } from '@monorepo/helper/cx'

import Header from '@/components/header'
import Footer from '@/components/footer'
import HeaderCarousel from '@/components/headerCarousel'
import Img from '@/components/Img'
import Video from '@/components/video'
import ClientPreviewWrapper from '@monorepo/helper/SanityClientPreviewWrapper'

// HELPERS

const HeaderImages = ({ headerFiles }: { headerFiles: Image[] }) => {
  if (!headerFiles || headerFiles.length === 0) {
    return null
  }

  if (headerFiles.length === 1) {
    return (
      <Img
        sizes="100vw"
        objectFit="cover"
        priority
        image={headerFiles[0]}
        className="h-full w-full"
      />
    )
  } else {
    return <HeaderCarousel images={headerFiles} />
  }
}

const HeaderVideo = ({ videoId }: { videoId: string }) => {
  return (
    <div className="2xl:max-w-145vh mx-auto max-w-screen-xl sm:px-5 2xl:px-0">
      <Video
        title=""
        className="absolute block h-full w-full"
        srcURL={`https://player.vimeo.com/video/${videoId}?portrait=0&color=DA291C&byline=0&title=0&speed=0&fun=0`}
      />
    </div>
  )
}

const HeaderMedia = ({
  headerFiles,
  headerVideoId,
}: {
  headerFiles?: Image[]
  headerVideoId?: string
}) => {
  if (headerVideoId) {
    return (
      <div className="relative w-full overflow-hidden">
        <HeaderVideo videoId={headerVideoId} />
      </div>
    )
  }

  if (headerFiles) {
    return (
      <div
        className="h-1/2-screen md:h-3/4-screen absolute w-full overflow-hidden"
        style={{
          top: 65,
        }}
      >
        <HeaderImages headerFiles={headerFiles} />
      </div>
    )
  }

  return null
}

// MAIN COMPONENT

type LayoutProps = {
  title?: string
  headerFiles?: Image[]
  headerVideoId?: string
  noPadTop?: boolean
  children: React.ReactNode
  isPreview?: boolean
  allPages: AllPagesQuery
}

export const Layout = ({
  title,
  headerFiles,
  headerVideoId,
  noPadTop = false,
  children,
  isPreview,
  allPages,
}: LayoutProps) => {
  const metaTitle = (title !== 'Home' ? `${title} — ` : '') + 'TBF + Partner AG'
  return (
    <ClientPreviewWrapper
      urlOrigin={process.env.NEXT_PUBLIC_PREVIEW_URL_WEBSITE!}
      isPreview={isPreview}
    >
      <div className="relative bg-gray-200">
        <Head>
          <title>{metaTitle}</title>
        </Head>

        <Header allPages={allPages} />
        <HeaderMedia headerFiles={headerFiles} headerVideoId={headerVideoId} />
        <main
          className={cx(
            'z-1 relative flex min-h-[25vh] flex-col',
            headerFiles &&
              !headerVideoId &&
              !noPadTop &&
              'mt-1/3-screen sm:mt-1/2-screen',
            ((!headerFiles && !noPadTop) || headerVideoId) && 'mt-8',
            noPadTop && 'mt-0',
          )}
        >
          {children}
        </main>
        <Footer />
      </div>
    </ClientPreviewWrapper>
  )
}

export default Layout
