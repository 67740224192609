import React from 'react'

import Carousel from '@/components/carousel'

const sanityConfig: any = {
  projectId: process.env.SANITY_PROJECT_ID,
  dataset: process.env.SANITY_DATASET,
}

type RichTextCarouselProps = {
  images: any[]
  imageOptions: any
  className?: string
  caption?: string
  size: string
}

const RichTextCarousel = ({
  imageOptions,
  images,
  className,
  caption,
  size,
}: RichTextCarouselProps) => {
  return (
    <div className={`mx-auto ${className}`}>
      <Carousel
        images={images}
        slideContainerClassName="h-full"
        objectFit="cover"
      />
      {caption && (
        <div className="text-md block pt-4 text-center">{caption}</div>
      )}
    </div>
  )
}

export default RichTextCarousel
