declare global {
  interface Window {
    plausible: any
  }
}

export interface PlausibleGoalSignatures {
  (event: 'use:search', props?: { searchQuery: string }): void
  (
    event: 'click:searchResult',
    props?: {
      elementType: string
      searchQuery: string
      elementPath: string
    },
  ): void
}

export const trackGoal: PlausibleGoalSignatures = (event, props) => {
  if (window.plausible) {
    window.plausible(event, { props })
  }
}
