import { cx } from '@monorepo/helper/cx'
import React from 'react'
import VimeoPlayer from '@/components/VimeoPlayer'

type VideoProps = {
  className?: string
  srcURL: string
  title: string
  size: string
  colorTheme: string
}

const RichTextVideo = ({
  className,
  srcURL,
  title,
  size,
  colorTheme,
}: VideoProps) => {
  return (
    <div className="mx-auto my-5 first:mt-0 sm:my-10">
      <div
        className={`pb-16/9 relative h-0 bg-black ${
          colorTheme === 'blue' ? 'border-8 border-yellow-500' : ''
        }`}
      >
        <VimeoPlayer
          className={cx('h-full w-full', className)}
          videoUrl={srcURL}
          options={{ autoplay: false, responsive: true }}
        />
      </div>
      {title && (
        <span
          className={`text-md block pt-4 text-center text-current ${
            colorTheme === 'blue' ? 'text-yellow-500' : ''
          }`}
        >
          {title}
        </span>
      )}
    </div>
  )
}

export default RichTextVideo
