import React, { useContext, useEffect, useState } from 'react'
import Link from 'next/link'
import { useTranslations } from 'next-intl'

import { stripNewLines } from '@/lib/withNewLines'
import { trackGoal } from '@/lib/analytics'
import { SearchQueryContext } from './search'
import type { Result } from '@/app/api/search/route'

// HELPERS

type ResultsCount = {
  relation: string
  value: string
}

type ResultCountProps = {
  count?: ResultsCount
}

const ResultCount = ({ count }: ResultCountProps) => {
  const t = useTranslations('search')

  if (count?.relation === 'eq') {
    const n = parseInt(count.value)
    return (
      <div className="text-sm text-gray-500">
        {n > 0 ? t('results', { count: n }) : ''}
      </div>
    )
  } else {
    return null
  }
}

type ResultFilter = {
  filters: any[]
  setFilters: (newFilters: any[]) => void
}

const ResultFilter = ({ filters, setFilters }: ResultFilter) => {
  const t = useTranslations('search')

  const toggleFilter = (key: string) => {
    if (filters.includes(key)) {
      const newFilters = filters.filter((f) => f !== key)
      setFilters(newFilters)
    } else {
      // Remove already selected filters
      setFilters([key])
    }
  }

  // The keys are the internal names of the website indeces
  const allTypes = [
    {
      key: 'website_trusted_advisor',
      label: t('trusted_advisor'),
    },
    {
      key: 'sanity_project',
      label: t('project'),
    },
    {
      key: 'sanity_story',
      label: t('story'),
    },
    {
      key: 'sanity_event',
      label: t('event'),
    },
    {
      key: 'sanity_job_offer',
      label: t('job_offer'),
    },
  ]

  return (
    <>
      <div className="text-sm text-gray-500">{t('filter_results')}</div>
      <div className="flex flex-wrap pb-6">
        {allTypes.map((type) => {
          return (
            <button
              key={type.key}
              className={`whitespace-no-wrap mr-2 cursor-pointer rounded-none border px-3 py-1 text-sm text-red-500 transition-colors ${
                filters.includes(type.key)
                  ? 'bg-yellow-500'
                  : 'bg-white hover:bg-yellow-500'
              }`}
              onClick={() => {
                toggleFilter(type.key)
              }}
            >
              {type.label}
            </button>
          )
        })}
      </div>
    </>
  )
}

const GenericSearchResult = ({
  title,
  subtitle,
  typeLabel,
}: {
  title: string | undefined
  subtitle?: string
  typeLabel: string
}) => {
  return (
    <div className="flex items-center justify-between">
      <div>
        <div className="text-lg leading-tight text-red-500 sm:text-xl">
          {title}
        </div>
        {subtitle && (
          <div className="text-md leading-tight sm:text-lg">{subtitle}</div>
        )}
      </div>
      <div className="bg-yellow-500 px-1 text-sm text-gray-500">
        {typeLabel}
      </div>
    </div>
  )
}

type ResultItemProps = {
  result: Result
  closeSearch: () => void
}

const ResultItem = ({ result, closeSearch }: ResultItemProps) => {
  const searchQuery = useContext(SearchQueryContext)
  const closeSearchAndTrackGoal = (
    elementType: string,
    elementPath: string,
  ) => {
    closeSearch()
    trackGoal('click:searchResult', { searchQuery, elementPath, elementType })
  }

  const linkClassNames =
    'block py-1 px-2 -mx-2 hover:bg-gray-200 transition-all duration-300 ease-in-out'

  const t = useTranslations('search')
  const typeTrackMap = {
    sanity_project: 'project',
    sanity_story: 'story',
    sanity_event: 'event',
    sanity_job_offer: 'jobOffer',
    sanity_service: 'service',
    sanity_domain: 'domain',
    sanity_page: 'page',
    website_trusted_advisor: 'trustedAdvisor',
  }
  const typeLabelMap = {
    sanity_project: t('project'),
    sanity_story: t('story'),
    sanity_event: t('event'),
    sanity_job_offer: t('job_offer'),
    sanity_service: t('service'),
    sanity_domain: t('domain'),
    sanity_page: t('page'),
    website_trusted_advisor: t('trusted_advisor'),
  }

  return (
    <Link
      className={linkClassNames}
      href={result.href}
      onClick={() =>
        closeSearchAndTrackGoal(typeTrackMap[result.type], result.href)
      }
    >
      <GenericSearchResult
        title={stripNewLines(result.title || '')}
        subtitle={result.subtitle || ''}
        typeLabel={typeLabelMap[result.type]}
      />
    </Link>
  )
}

// MAIN COMPONENT

type SearchResultsProps = {
  query: string
  results: Result[]
  resultsCount?: ResultsCount
  isLoading: boolean
  filters: any[]
  searchOpen: boolean
  error?: string
  closeSearch: () => void
  setFilters: (newFilters: any[]) => void
}

const SearchResults = ({
  query,
  results,
  resultsCount,
  filters,
  isLoading,
  error,
  searchOpen,
  closeSearch,
  setFilters,
}: SearchResultsProps) => {
  const t = useTranslations('search')
  const [searchWasActive, setSearchWasActive] = useState(false)
  const show = searchWasActive && searchOpen
  const hasResults = results.length > 0
  const infoText = isLoading ? t('loading') : error || t('empty')

  useEffect(() => {
    const searchActive = searchOpen && query && query.length > 0
    if (searchActive) setSearchWasActive(true)
    if (!searchOpen) setSearchWasActive(false)
  }, [searchOpen, query])

  const containerClasses = show
    ? 'py-6 opacity-1 scale-y-100'
    : 'opacity-0 scale-y-0'
  const containerStyles = show
    ? {
        boxShadow: 'inset 0 3px 11px 0 rgba(0, 0, 0, 0.10)',
      }
    : { boxShadow: 'none' }

  return (
    <div
      className={`min-h-2/5-screen absolute left-0 right-0 w-full origin-top overflow-hidden bg-white transition-all duration-500 ease-in-out ${containerClasses}`}
      style={containerStyles}
    >
      <div className="mx-auto w-4/5 max-w-screen-lg sm:w-2/3">
        <ResultFilter filters={filters} setFilters={setFilters} />
        <ResultCount count={resultsCount} />
        {hasResults ? (
          <>
            {results?.map((result) => (
              <ResultItem
                key={`${result.type}-${result.id}`}
                result={result}
                closeSearch={closeSearch}
              />
            ))}
          </>
        ) : (
          <div className="block py-2">{infoText}</div>
        )}
      </div>
    </div>
  )
}

export default SearchResults
