import React from 'react'

type TagProps = {
  children: React.ReactNode
}

export const YellowTag = ({ children }: TagProps) => {
  return (
    <h1
      className="scale-85 xs:scale-90 inline-block bg-yellow-500 px-3 py-1 text-xs uppercase md:scale-100 lg:mt-4"
      style={{
        color: '#332e00',
      }}
    >
      {children}
    </h1>
  )
}
