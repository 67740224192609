import React from 'react'
import { useTranslations } from 'next-intl'

type FooterProps = {
  className?: string
}

const Footer = ({ className }: FooterProps) => {
  const t = useTranslations('footer')
  const wrapperClass = className ?? 'bg-gray-200'

  return (
    <div className={wrapperClass}>
      <div className="mx-auto flex max-w-screen-xl flex-wrap pb-20 pt-20 text-xs">
        <div>
          <h3 className="ml-6 mt-6 font-medium">{t('locationsSwitzerland')}</h3>
          <div className="flex flex-wrap">
            <div className="mx-6 my-4">
              TBF + Partner AG <br />
              Schwanengasse 12 <br />
              3011 {t('bern')}
            </div>
            <div className="mx-6 my-4">
              TBF + Partner AG <br />
              Quai du Seujet 10 <br />
              1201 {t('genf')}
            </div>
            <div className="mx-6 my-4">
              TBF + Partner AG <br />
              Via Besso 42 <br />
              6900 {t('lugano')}
            </div>
            <div className="mx-6 my-4">
              TBF + Partner AG <br />
              Beckenhofstrasse 35 <br />
              Postfach <br />
              8042 {t('zurich')}
            </div>
          </div>
        </div>
        <div>
          <h4 className="ml-6 mt-6 font-medium">{t('locationsGermany')}</h4>
          <div className="flex flex-wrap">
            <div className="mx-6 my-4">
              TBF + Partner AG <br />
              Alsterarkaden 9 <br />
              20354 {t('hamburg')}
            </div>
            <div className="mx-6 my-4">
              TBF + Partner AG <br />
              Mauerkircherstrasse 9 <br />
              81679 {t('munich')}
            </div>
            <div className="mx-6 my-4">
              TBF + Partner AG <br />
              Schlossstrasse 70 <br />
              70176 {t('stuttgart')}
            </div>
          </div>
        </div>
        <div>
          <h4 className="ml-6 mt-6 font-medium">{t('locationsItaly')}</h4>
          <div className="flex flex-wrap">
            <div className="mx-6 my-4">
              TBF + Partner S.r.l. <br />
              Via Pola 11 <br />
              20124 {t('milan')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
