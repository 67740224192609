import React from 'react'

const withNewLines = (str?: string | null) => {
  return (str || '')
    .split('\\n')
    .reduce(
      (acc, strFragment, i) =>
        acc.length === 0
          ? [strFragment]
          : [...acc, <br key={i} className="hidden md:block" />, strFragment],
      [] as any[],
    )
}

export const stripNewLines = (str?: string | null) => {
  return str?.replace(/\\n/g, ' ')
}

export default withNewLines
