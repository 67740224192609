import { Vimeo } from '@monorepo/helper/Vimeo'
import React, { useEffect, useRef } from 'react'

const isVimeoUrlOrId = (urlOrId: string | number) =>
  urlOrId.toString().includes('vimeo.com') || !isNaN(Number(urlOrId))

type VideoProps = {
  className?: string
  srcURL: string
  title?: string
  playing?: boolean
}

const whiteControls = (url: string) =>
  url.includes('color=')
    ? url
    : url.includes('?')
      ? `${url}&color=ffffff`
      : `${url}?color=ffffff`

const Video = ({ className, srcURL, title, playing }: VideoProps) => {
  const videoRef = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (typeof playing === 'undefined') return
    videoRef.current?.contentWindow?.postMessage(
      { method: playing ? 'play' : 'pause' },
      '*',
    )
  }, [playing])

  return (
    <div className="pb-16/9 relative h-0 bg-black">
      {isVimeoUrlOrId(srcURL) ? (
        <Vimeo
          urlOrId={srcURL}
          className={className}
          title={title}
          autoplay={playing}
        />
      ) : (
        <iframe
          ref={videoRef}
          className={className}
          src={srcURL && whiteControls(srcURL)}
          title={title || 'Video'}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          frameBorder="0"
          allowFullScreen
        />
      )}
    </div>
  )
}

export default Video
