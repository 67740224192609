import React, { useEffect, useRef, useState } from 'react'
import { ChevronCircleDown } from '@/components/icons'
import {
  PageTile,
  ProjectTile,
  StoryTile,
  AboutUsVideoTile,
} from '@/components/tile'
import { useTranslations } from 'next-intl'

// HELPER COMPONENTS

export const SectionItem = ({ data, textSize }: any) => {
  switch (data?.link?._type) {
    case 'story':
      return <StoryTile tile={data} textSize={textSize} />

    case 'project':
      return <ProjectTile tile={data} textSize={textSize} />

    case 'page':
      return <PageTile tile={data} textSize={textSize} />

    case 'aboutUsVideoSection':
      return <AboutUsVideoTile tile={data} textSize={textSize} />

    default:
      return null
  }
}

const LayoutA = ({ items }: any) => {
  return (
    <div>
      <div className="flex flex-wrap lg:hidden portrait:flex">
        <div className="h-4/5-screen w-full">
          <SectionItem textSize="2/3-1-1" data={items[0]} />
        </div>
        <div className="flex w-full flex-wrap">
          <div className="h-4/5-screen sm:h-1/2-screen-noheader w-full sm:w-1/2">
            <SectionItem textSize="1/2-1/2-1" data={items[1]} />
          </div>
          <div className="h-4/5-screen sm:h-1/2-screen-noheader w-full sm:w-1/2">
            <SectionItem textSize="1/2-1/2-1" data={items[2]} />
          </div>
        </div>
      </div>

      <div className="hidden flex-wrap lg:flex portrait:hidden">
        <div className="flex w-2/3 flex-wrap">
          <div className="pb-16/9 relative w-full">
            <div className="absolute left-0 top-0 h-full w-full">
              <SectionItem textSize="2/3-1-1" data={items[0]} />
            </div>
          </div>
          <div className="pb-16/9 relative w-full">
            <div className="absolute left-0 top-0 h-full w-full">
              <SectionItem textSize="2/3-1/2-1" data={items[2]} />
            </div>
          </div>
        </div>
        <div className="flex w-1/3 flex-wrap">
          <div className="h-full w-full">
            <SectionItem textSize="1/3-1/2-1" data={items[1]} />
          </div>
        </div>
      </div>
      <ScrollDownButton />
    </div>
  )
}

const LayoutB = ({ items }: any) => {
  return (
    <div>
      <div className="flex flex-wrap portrait:hidden">
        <div
          className="w-2/3"
          style={{
            height: 'calc(100vh - 65px)',
          }}
        >
          <SectionItem textSize="2/3-1-1" data={items[0]} />
        </div>
        <div className="flex h-full w-1/3 flex-wrap">
          <div
            className="w-full sm:w-full"
            style={{
              height: 'calc(50vh - 33px)',
            }}
          >
            <SectionItem textSize="1/3-1/2-1" data={items[1]} />
          </div>
          <div
            className="w-full sm:w-full"
            style={{
              height: 'calc(50vh - 32px)',
            }}
          >
            <SectionItem textSize="1/3-1/2-1" data={items[2]} />
          </div>
        </div>
      </div>

      <div className="hidden flex-wrap portrait:flex">
        <div className="h-4/5-screen sm:h-screen-noheader w-full">
          <SectionItem textSize="1-1-1" data={items[0]} />
        </div>
        <div className="flex h-full w-full flex-wrap">
          <div className="h-4/5-screen sm:h-1/2-screen w-full sm:w-1/2">
            <SectionItem textSize="1/2-1/2-1" data={items[1]} />
          </div>
          <div className="h-4/5-screen sm:h-1/2-screen w-full sm:w-1/2">
            <SectionItem textSize="1/2-1/2-1" data={items[2]} />
          </div>
        </div>
      </div>
      <ScrollDownButton />
    </div>
  )
}

const LayoutC = ({ items }: any) => {
  return (
    <div>
      <div className="h-4/5-screen sm:h-screen-noheader w-full">
        <SectionItem textSize="1-1-1" data={items[0]} />
      </div>
      <div className="flex w-full flex-wrap">
        <div className="h-4/5-screen sm:h-1/2-screen w-full sm:w-1/2">
          <SectionItem textSize="1/2-1/2-1" data={items[1]} />
        </div>
        <div className="h-4/5-screen sm:h-1/2-screen w-full sm:w-1/2">
          <SectionItem textSize="1/2-1/2-1" data={items[2]} />
        </div>
      </div>
      <ScrollDownButton />
    </div>
  )
}

const ScrollDownButton = () => {
  const [show, setShow] = useState(true)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const t = useTranslations('home')

  const click = () => {
    window.scrollTo({
      top: buttonRef.current?.parentElement?.getBoundingClientRect().bottom,
      behavior: 'smooth',
    })
  }

  const handleScroll = () => {
    const { pageYOffset } = window
    const targetYOffset = 100
    setShow(pageYOffset < targetYOffset)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true })

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <div
      className={`pointer-events-none fixed bottom-0 z-50 w-full pt-12 transition-all duration-150 ease-in-out ${
        show ? 'opactity-100' : 'opacity-0'
      }`}
      style={{
        background:
          'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.8) 100%)',
      }}
    >
      <button
        onClick={click}
        ref={buttonRef}
        className={`pointer-events-auto relative left-1/2 mb-5 -translate-x-1/2 cursor-pointer text-center uppercase leading-none tracking-wider text-white duration-150 ease-in-out focus:outline-none sm:text-lg ${
          show ? 'pointer-events-all' : 'pointer-events-none translate-y-8'
        }`}
      >
        {t('scrollDown')}
        <br />
        <ChevronCircleDown className="mt-1 inline-block w-5 fill-current text-sm text-white" />
      </button>
    </div>
  )
}

// MAIN COMPONENT

const SectionItemsLayout = ({ layoutName, items }: any) => {
  switch (layoutName) {
    case 'layoutA':
      return <LayoutA items={items} />

    case 'layoutB':
      return <LayoutB items={items} />

    case 'layoutC':
      return <LayoutC items={items} />

    default:
      return (
        <div>
          {items?.map((section: any) => (
            <SectionItem
              key={section.id ?? section._id ?? section._key}
              data={section}
            />
          ))}
        </div>
      )
  }
}

export default SectionItemsLayout
