'use client'
import { useState, useEffect } from 'react'
import Image from 'next/image'
import Head from 'next/head'
import { cx } from '../cx'
import type { DeepNullablePartial } from '../types'

type VimeoVideoQuality =
  | 'auto'
  | '4K'
  | '2K'
  | '1080p'
  | '720p'
  | '540p'
  | '360p'
  | '240p'

export interface VimeoOptions {
  autopause?: boolean | undefined
  autoplay?: boolean | undefined
  background?: boolean | undefined
  byline?: boolean | undefined
  color?: string | undefined
  controls?: boolean | undefined
  dnt?: boolean | undefined
  height?: number | undefined
  interactive_params?: string | undefined
  keyboard?: boolean | undefined
  loop?: boolean | undefined
  maxheight?: number | undefined
  maxwidth?: number | undefined
  muted?: boolean | undefined
  pip?: boolean | undefined
  playsinline?: boolean | undefined
  portrait?: boolean | undefined
  responsive?: boolean | undefined
  speed?: boolean | undefined
  quality?: VimeoVideoQuality | undefined
  texttrack?: string | undefined
  title?: boolean | undefined
  transparent?: boolean | undefined
  width?: number | undefined
}

const getVimeoId = (url: string | number) => {
  const arr = url
    .toString()
    .split(
      /(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/,
    )

  return undefined !== arr[5] ? arr[5] : arr[0]
}

function PlayIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      height="1em"
      preserveAspectRatio="xMidYMid"
      viewBox="0 0 20 20"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="m1 0 19 10L1 20z" fill="currentColor" />
    </svg>
  )
}

type GetVimeoPosterUrlProps = {
  urlOrId: string | number
  posterSize?: string
}

async function getVimeoPosterUrl({
  urlOrId,
  posterSize = '1920x1080',
}: GetVimeoPosterUrlProps) {
  try {
    const videoId = getVimeoId(urlOrId)
    const response = await fetch(
      `https://vimeo.com/api/oembed.json?url=https%3A//vimeo.com/${videoId}`,
    )
    const data = await response.json()

    const thumbnailUrl = data.thumbnail_url || ''

    return thumbnailUrl
      ? `${thumbnailUrl.replace(/\d+x\d+/, posterSize)}.webp`
      : ''
  } catch (error) {
    return ''
  }
}

const transformBooleanValues = (options?: VimeoOptions) => {
  if (!options) return {}
  return Object.entries(options).reduce(
    (acc, [key, value]) => {
      if (typeof value === 'boolean') {
        return { ...acc, [key]: value ? 1 : 0 }
      }
      if (value) {
        return { ...acc, [key]: value }
      }
      return acc
    },
    {} as Record<string, string>,
  )
}

type VimeoProps = {
  urlOrId: string | number
  options?: VimeoOptions
  className?: string
  autoplay?: boolean
  title?: string
  iframeStyle?: React.CSSProperties
  customPoster?: DeepNullablePartial<{
    asset?: {
      metadata?: {
        dimensions?: {
          width?: number
          height?: number
        }
        lqip?: string
      }
      url?: string
      altText?: string
    }
  }>
}

export const Vimeo = ({
  urlOrId,
  className,
  options,
  autoplay,
  title,
  iframeStyle = { position: 'absolute', width: '100%', height: '100%' },
  customPoster,
}: VimeoProps) => {
  const [isVimeoPlayerEnabled, setIsVimeoPlayerEnabled] = useState(autoplay)
  const [isHovering, setIsHovering] = useState(false)
  const [posterUrl, setPosterUrl] = useState(customPoster?.asset?.url || '')

  const params = new URLSearchParams(
    transformBooleanValues({ ...options, autoplay: true }),
  )
  const videoId = getVimeoId(urlOrId)
  const iframeSrc = `https://player.vimeo.com/video/${videoId}?h=${Math.random()}&${params.toString()}&autoplay=1`

  const enableVimeoPlayer = () => {
    !isVimeoPlayerEnabled && setIsVimeoPlayerEnabled(true)
  }

  useEffect(() => {
    if (!customPoster) {
      getVimeoPosterUrl({ urlOrId }).then((url) => {
        url && setPosterUrl(url)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlOrId])

  return (
    <div className={cx('pb-16/9 relative h-0 bg-black', className)}>
      <Head>
        <link type="preconnect" href="https://player.vimeo.com" />
        <link type="preconnect" href="https://i.vimeocdn.com" />
        <link type="preconnect" href="https://f.vimeocdn.com" />
      </Head>
      {posterUrl && (
        <Image
          sizes="(min-width: 1080px) 896px, (min-width: 640px) 91.43vw, 100vw"
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          src={posterUrl}
          alt={customPoster?.asset?.altText || 'Vimeo video'}
          width={customPoster?.asset?.metadata?.dimensions?.width || '1920'}
          height={customPoster?.asset?.metadata?.dimensions?.height || '1080'}
          placeholder={customPoster?.asset?.metadata?.lqip ? 'blur' : undefined}
          blurDataURL={customPoster?.asset?.metadata?.lqip || undefined}
        />
      )}
      {isVimeoPlayerEnabled ? (
        <iframe
          src={iframeSrc}
          frameBorder="0"
          allowFullScreen
          style={iframeStyle}
          title={title || 'Vimeo video'}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        />
      ) : (
        <button
          style={{
            appearance: 'none',
            border: 0,
            borderRadius: 5,
            boxSizing: 'border-box',
            color: '#fff',
            height: 40,
            left: '50%',
            lineHeight: 'normal',
            margin: 0,
            padding: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translate3d(-50%, -50%, 0)',
            transition:
              'opacity 250ms ease-out, background-color 40ms, color 40ms',
            verticalAlign: 'baseline',
            width: 65,
            backgroundColor: isHovering ? '#00adef' : 'rgba(30, 30, 30, 0.9)',
          }}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          onClick={enableVimeoPlayer}
          aria-label="Play video"
        >
          <PlayIcon
            style={{
              width: 20,
              height: 20,
              boxSizing: 'border-box',
              display: 'inline-flex',
            }}
          />
        </button>
      )}
    </div>
  )
}
