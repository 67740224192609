import type { Image as SanityImage } from '@/.generated/sanityTypes/graphql'

import React from 'react'
import type { ImageProps } from 'next/image'
import Image from 'next/image'

type ObjectFit = 'contain' | 'cover' | 'fill' | 'none' | 'scale-down'
type SvgOrImgProps = {
  image: SanityImage
  alt?: string
  style?: React.CSSProperties
  objectFit?: string
  objectPosition?: string
  className?: string
  fill?: ImageProps['fill']
  priority?: ImageProps['priority']
  sizes?: ImageProps['sizes']
}

const isObjectFit = (value: any): value is ObjectFit => {
  return ['contain', 'cover', 'fill', 'none', 'scale-down'].includes(value)
}

const SvgOrImg = ({
  image,
  alt,
  style,
  objectFit,
  objectPosition: objectPositionProp,
  className,
  fill,
  priority,
  sizes,
}: SvgOrImgProps) => {
  if (!image.asset || !image.asset.url) return null
  const objectPosition =
    objectPositionProp ||
    `${(image?.hotspot?.x ?? 0.5) * 100}% ${(image?.hotspot?.y ?? 0.5) * 100}%`

  return (
    <Image
      key={image._key}
      src={image.asset.url}
      width={fill ? undefined : image.asset.metadata?.dimensions?.width!}
      height={fill ? undefined : image.asset.metadata?.dimensions?.height!}
      style={{
        objectFit: isObjectFit(objectFit) ? objectFit : 'fill',
        objectPosition,
        ...style,
      }}
      priority={priority}
      className={className}
      placeholder="blur"
      fill={fill}
      sizes={sizes}
      blurDataURL={image.asset.metadata?.lqip!}
      alt={alt || image.asset.title || image.asset.altText || ''}
    />
  )
}

export default SvgOrImg
