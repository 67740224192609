import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTransition, useTrail, animated } from '@react-spring/web'
import { cx } from '@monorepo/helper/cx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'

import { ChevronDown, SearchIcon, MenuIcon } from '@/components/icons'
import Search from '@/components/search'
import type { AllPagesQuery } from '@/.generated/sanityTypes/graphql'
import Image from 'next/image'
import tbfLogo from '../../public/images/tbf_logo.svg'
import { walsheim } from '@/lib/fonts'

// CONFIG

const menuItems = [
  {
    intlKey: 'home',
    to: '/',
  },
  {
    slug: 'about',
  },
  {
    slug: 'contact-partners',
  },
  {
    slug: 'competences',
  },
  {
    slug: 'projects',
  },
  {
    slug: 'stories',
  },
  {
    slug: 'jobs',
  },
  {
    slug: 'contact',
  },
]

// HELPER COMPONENTS

type MenuHeaderProps = {
  setMenuOpen: (newMenuOpen: boolean) => void
  searchOpen: boolean
  setSearchOpen: (newSearchOpen: boolean) => void
}

const MenuHeader = ({
  setMenuOpen,
  searchOpen,
  setSearchOpen,
}: MenuHeaderProps) => {
  const t = useTranslations('menu')

  return (
    <div
      className={cx(
        'translate-y- absolute top-0 mt-2 flex w-full items-center transition duration-500 ease-in-out',
        searchOpen && '-translate-y-16',
      )}
    >
      <button
        title={t('open')}
        className="ml-2 inline-block h-12 w-12 cursor-pointer self-center outline-none focus:outline-none"
        data-cy="open-menu-button"
        onClick={() => setMenuOpen(true)}
      >
        <MenuIcon color="rgb(218, 41, 28)" />
      </button>
      <div className="flex-1" style={{ marginRight: 56 }}>
        <Link href="/" title="TBF + Partner">
          <Image
            alt="TBF + Partner"
            className="crop-on-mobile mx-auto w-40 pt-2"
            src={tbfLogo}
          />
        </Link>
      </div>
      <div className="absolute bottom-0 right-0 top-0 flex items-center pr-6">
        <SearchButton searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
        <LanguageSelector />
      </div>
    </div>
  )
}

type OverlayProps = {
  searchOpen: boolean
  setSearchOpen: (newSearchOpen: boolean) => void
}

const Overlay = ({ searchOpen, setSearchOpen }: OverlayProps) => {
  return (
    <div
      className={cx(
        'absolute bottom-0 left-0 right-0 top-0 z-20 transition duration-500 ease-in-out',
        searchOpen ? 'bg-black-75' : 'pointer-events-none bg-opacity-0',
      )}
      onClick={() => {
        setSearchOpen(false)
      }}
    ></div>
  )
}

const LanguageSelector = () => {
  const router = useRouter()

  const selectProps = {
    className:
      'relative py-1 pl-2 pr-4 text-red-500 bg-transparent hover:bg-red/12 focus:bg-red/12 outline-none appearance-none cursor-pointer bg-none border-none text-xs',
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
      router.push(router.pathname, router.asPath, { locale: e.target.value })
    },
  }
  return (
    <div className="p-2 pl-0">
      <div className="relative overflow-hidden border border-red-500 p-1 text-xs font-light">
        <select
          {...selectProps}
          className={`${selectProps.className} md:hidden`}
          defaultValue={router.locale}
          aria-label="Language"
        >
          <option value="de">DE</option>
          <option value="fr">FR</option>
          <option value="it">IT</option>
          <option value="en">EN</option>
        </select>
        <select
          {...selectProps}
          className={`${selectProps.className} hidden md:block`}
          defaultValue={router.locale}
          aria-label="Language"
        >
          <option value="de">Deutsch</option>
          <option value="fr">Français</option>
          <option value="it">Italiano</option>
          <option value="en">English</option>
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2">
          <ChevronDown className="fill-red w-2" />
        </div>
      </div>
    </div>
  )
}

type SearchButtonProps = {
  searchOpen: boolean
  setSearchOpen: (newIsOpen: boolean) => void
}

const SearchButton = ({ searchOpen, setSearchOpen }: SearchButtonProps) => {
  const t = useTranslations('search')
  return (
    <button
      className="p-2"
      onClick={() => {
        setSearchOpen(!searchOpen)
      }}
      aria-label={t('search')}
    >
      <span className="border-red-highlight relative block cursor-pointer appearance-none border p-2 text-xs outline-none hover:bg-red-100 focus:bg-red-100 focus:outline-none">
        <SearchIcon className="fill-red pointer-events-none h-4 w-4" />
      </span>
    </button>
  )
}

type MenuProps = {
  open: boolean
  onClose: () => void
  allPages: AllPagesQuery
}

const Menu = ({ open, onClose, allPages }: MenuProps) => {
  const t = useTranslations('menu')
  const router = useRouter()
  const { locale = 'de' } = router

  const openMenutransitions = useTransition(open, {
    from: { width: '0vw', height: '0vh' },
    enter: { width: '100vw', height: '100vh' },
    leave: { width: '0vw', height: '0vh' },
  })

  // This is the critical damping formula
  // friction^ 2 = 4 * mass * tension
  // We always define mass = 1 (also default value)
  const friction = 60
  const tension = (friction * friction) / 4

  const [menuItemsTrail, set] = useTrail(menuItems.length, () => ({
    opacity: 0,
    transform: 'translate3d(-100px,0,0)',
    config: {
      tension: tension,
      friction: friction,
    },
  }))

  set(
    open
      ? { opacity: 1, transform: 'translate3d(0px,0,0)' }
      : { opacity: 0, transform: 'translate3d(-100px,0,0)' },
  )

  return (
    <>
      {openMenutransitions((props, item) => {
        return (
          item && (
            <animated.div
              style={props}
              className="bg-yellow-highlight fixed left-0 top-0 z-50 overflow-hidden"
              data-cy="menu"
              onClick={() => onClose()}
            >
              <div className="h-screen w-screen overflow-auto">
                <button
                  onClick={() => onClose()}
                  title={t('close')}
                  className="fixed left-0 top-0 ml-2 mt-2 inline-block h-12 w-12 cursor-pointer focus:outline-none"
                  data-cy="close-menu-button"
                >
                  <MenuIcon color="rgb(218, 41, 28)" />
                </button>
                <div className="flex min-h-screen flex-col justify-evenly">
                  <div className="text-2.5xl pb-16 pt-16 text-center leading-snug sm:text-5xl">
                    <ul className={walsheim.className}>
                      {menuItemsTrail.map((props, index) => {
                        const menuItem = menuItems[index]
                        const page = menuItem.slug
                          ? allPages.allPage.find(
                              (p) => p.pageSlug?.current === menuItem.slug,
                            )
                          : null

                        const pageName = page
                          ? (page?.menuTitleOverride as any)?.[locale] ||
                            (page?.pageHeader?.sectionTitle as any)[locale]
                          : menuItem.intlKey
                            ? t(menuItem.intlKey)
                            : '(no title)'

                        const pageLink = menuItem.to
                          ? menuItem.to
                          : `/${page?.pageSlug?.current}`

                        const isActive = router.asPath === pageLink

                        return (
                          <animated.li style={props} key={pageLink}>
                            <Link
                              className={cx(
                                'hover:text-red-highlight transition-colors duration-200',
                                isActive && 'text-red-highlight',
                              )}
                              href={pageLink}
                            >
                              {pageName}
                            </Link>
                          </animated.li>
                        )
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </animated.div>
          )
        )
      })}
    </>
  )
}

// MAIN COMPONENT

const Header = ({ allPages }: { allPages: any }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [searchOpen, setSearchOpen] = useState(false)

  const menuPortal =
    typeof document !== `undefined`
      ? ReactDOM.createPortal(
          <Menu
            allPages={allPages}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
          />,
          document.body,
        )
      : null

  useEffect(() => {
    if (menuOpen) document.body.classList.add('overflow-hidden')
    else document.body.classList.remove('overflow-hidden')

    return () => document.body.classList.remove('overflow-hidden')
  }, [menuOpen])

  return (
    <>
      <div
        className="relative z-40 bg-white pb-3 pt-4 shadow"
        style={{ height: '65px' }}
      >
        <Search searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
        <MenuHeader
          setMenuOpen={setMenuOpen}
          searchOpen={searchOpen}
          setSearchOpen={setSearchOpen}
        />
      </div>
      <Overlay searchOpen={searchOpen} setSearchOpen={setSearchOpen} />
      {menuPortal}
    </>
  )
}

export default Header
