import React from 'react'
import { BlackButton } from '@/components/buttons'

type RichTextPDFProps = {
  title: string
  file: {
    asset: {
      url: string
    }
  }
}

const RichTextPDF = ({ title, file }: RichTextPDFProps) => {
  return (
    <BlackButton target="_blank" className="mb-6 mt-5" href={file?.asset.url}>
      {title}
    </BlackButton>
  )
}

export default RichTextPDF
